import React, {useRef, useState} from 'react';
import ImageReveal from '../components/ImageReveal';
import Image1A from '../images/1-A.jpg';
import Image1B from '../images/1-B.jpg';
import Image2A from '../images/2-A.jpg';
import Image2B from '../images/2-B.jpg';
import BubblesBackground from "../components/BubblesBackground";
import ProductCard from "../components/ProductCard";
import Slider from "react-slick";
import {CarouselNextArrow, CarouselPrevArrow} from "../components/CarouselComponents";

import Banner1A from '../images/Banner_1A.jpg';
import Banner1B from '../images/Banner_1B.jpg';
import Banner2A from '../images/Banner_2A.jpg';
import Banner2B from '../images/Banner_2B.jpg';
import Banner3A from '../images/Banner_3A.jpg';
import Banner3B from '../images/Banner_3B.jpg';
import SectionTitle from "../components/SectionTitle";
import productData from '../productData.json';
import DataLayer from "../components/DataLayer";

const defaultSpeed = 500;  // Default speed for animations

const HomePage = () => {
    const [, setSliderSpeed] = useState(defaultSpeed);
    const containerRef = useRef(null);

    const productSliderSettings = {
        dots: false,
        infinite: true,
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 250,
        autoplaySpeed: 3000,
        cssEase: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
        pauseOnHover: true,
        nextArrow: <CarouselNextArrow setSliderSpeed={setSliderSpeed}/>,
        prevArrow: <CarouselPrevArrow setSliderSpeed={setSliderSpeed}/>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <DataLayer
                pageName="Home"
                pageLanguage="es-MX"
                contentType="home"
                brandCategory="Beauty & Wellbeing"
                siteSection1="Ponds Institute Mexico"
                siteSection2=""
                siteSection3=""
            />
            <div className="flex flex-col items-center">
                <div className="w-full max-w-screen-lg">
                    <video className="max-w-full h-auto" autoPlay muted playsInline>
                        <source src="/Ponds_WEB_1280x720.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="w-full max-w-screen-lg mt-3">
                    <div className="flex gap-3 overflow-hidden">
                        <ImageReveal imageA={Image1A} imageB={Image1B}/>
                        <ImageReveal imageA={Image2A} imageB={Image2B}/>
                    </div>
                </div>
                <div ref={containerRef} className="w-full max-w-screen-lg mt-10 overflow-hidden">
                    <div className="flex gap-5 py-5">
                        <BubblesBackground containerRef={containerRef}/>
                    </div>
                </div>
                <div className="w-full mt-10">
                    <SectionTitle title="EXPLORA LOS PRODUCTOS"/>
                </div>
                <div className="w-full max-w-screen-lg mt-10 px-7 h-auto">
                    <Slider {...productSliderSettings}>
                        {productData.products.map((product) => {
                            const {id, type, officialName, images, category, weight, link} = product;
                            const productImage = require(`../images/${images[0]}`);
                            return (
                                <ProductCard
                                    key={id}
                                    productType={type}
                                    productName={officialName}
                                    productImage={productImage}
                                    brand={category}
                                    weight={weight}
                                    link={link}
                                />
                            );
                        })}
                    </Slider>
                </div>
                <div className="w-full mt-10">
                    <SectionTitle id="beneficio" title="CONOCE MÁS DE LOS BENEFICIOS"/>
                </div>
                <div className="w-full max-w-screen-lg mt-10">
                    <ImageReveal imageA={Banner1A} imageB={Banner1B}/>
                </div>
                <div className="w-full max-w-screen-lg mt-3">
                    <div className="flex gap-3 overflow-hidden">
                        <ImageReveal imageA={Banner2A} imageB={Banner2B}/>
                        <ImageReveal imageA={Banner3A} imageB={Banner3B}/>
                    </div>
                </div>
                <div className="w-full mt-10">
                    <SectionTitle id="skin-inspiration" title="SKIN INSPIRATION"/>
                </div>
            </div>
        </>
    )
        ;
}

export default HomePage;