import React, {useState, useRef, useEffect} from 'react';
import {useSpring, animated} from 'react-spring';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faTimes} from '@fortawesome/free-solid-svg-icons';
import logo from '../images/logo-08.png';
import './Header.css'
import {Link} from 'react-router-dom';

const MenuIcon = ({isOpen, toggleMenu}) => {
    const iconTransition = useSpring({
        opacity: isOpen ? 1 : 0,
    });

    return (
        <div className="sm:hidden z-50 cursor-pointer fixed top-13 right-5" onClick={toggleMenu}>
            {isOpen ? (
                <animated.div style={iconTransition}>
                    <div className="mr-4">
                        <FontAwesomeIcon icon={faTimes}/>
                    </div>
                </animated.div>
            ) : (
                <div className="mr-4">
                    <FontAwesomeIcon icon={faBars}/>
                </div>
            )}
        </div>
    );
};

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef();

    const menuAnimation = useSpring({
        to: {transform: isOpen ? 'translateX(0%)' : 'translateX(100%)'},
        from: {transform: 'translateX(100%)'},
    });

    const toggleMenu = () => setIsOpen(prev => !prev);

    // Detecting outside click to close menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <header className="bg-white">
            <nav className="container mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
                {/* Logo - Hidden on screens larger than mobile */}
                <div className="flex-shrink-0 mx-auto sm:hidden">
                    <Link to="/">
                        <img src={logo} alt="Pond's Skin Institute" className="max-h-24"/>
                    </Link>
                </div>

                {/* Burger Icon */}
                <MenuIcon isOpen={isOpen} toggleMenu={toggleMenu}/>

                {/* Animated menu */}
                <animated.div
                    ref={menuRef}
                    className="fixed top-0 right-0 w-screen h-full z-40 shadow-lg bg-white"
                    style={menuAnimation}
                >
                    <div className="flex flex-col items-center justify-center h-full px-4">
                        {/* Menu Items */}
                        <div className="flex-shrink-0">
                            <Link to="/" onClick={toggleMenu}>
                                <img src={logo} alt="Pond's Skin Institute" className="max-h-24"/>
                            </Link>
                        </div>
                        <Link to="/" className="my-4 text-lg font-medium font-menu text-gray-600 hover:text-gray-900"
                              onClick={toggleMenu}>INICIO</Link>
                        <Link
                            to="acerca-de-ponds-skin-institute"
                            className="my-4 text-lg font-medium font-menu text-gray-600 hover:text-gray-900"
                            onClick={toggleMenu}>Acerca de Pond´s Institute</Link>
                        <Link
                            to="ai-skin-expert"
                            className="my-4 text-lg font-medium font-menu text-gray-600 hover:text-gray-900"
                            onClick={toggleMenu}>A.I. Skin Expert</Link>
                    </div>
                </animated.div>

                <div className="hidden md:grid grid-cols-3 items-center mx-auto px-2 sm:px-6 lg:px-2 py-4">
                    {/* Left Placeholder for balance */}
                    <div>
                    <div className="text-left">
                        <Link to="ai-skin-expert"
                              className="md:ml-5 lg:ml-24 text-sm font-menu font-medium text-gray-600 hover:text-gray-900">A.I. Skin Expert</Link>
                    </div>
                    </div>

                    {/* Center Logo */}
                    <div className="flex justify-center">
                        <Link to="/">
                            <img src={logo} alt="Pond's Skin Institute" className="max-h-24"/>
                        </Link>
                    </div>

                    {/* Right Placeholder for balance */}
                    <div className="text-right">
                        <Link to="acerca-de-ponds-skin-institute"
                              className="md:ml-5 lg:ml-24 text-sm font-menu font-medium text-gray-600 hover:text-gray-900">Acerca de
                            Pond´s Skin Institute</Link>
                    </div>
                </div>

            </nav>
        </header>
    );
};

export default Header;