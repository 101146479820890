import React, { useEffect } from 'react';
import { Chrono } from 'react-chrono';
import { useMediaQuery } from 'react-responsive';
import Ponds1846 from '../images/ponds-1846.png';
import Ponds1857 from '../images/ponds-1857.png';
import Ponds1907 from '../images/ponds-1907.png';
import Ponds1924 from '../images/ponds-1924.png';
import Ponds1978 from '../images/ponds-1978.png';
import Ponds1992 from '../images/ponds-1992.png';
import Ponds1994 from '../images/ponds-1994.png';
import Ponds2006 from '../images/ponds-2006.png';
import Ponds2024 from '../images/ponds-2024.gif';
import DataLayer from "../components/DataLayer";
import {Helmet} from "react-helmet-async";

const items = [
    {
        title: "1846",
        cardTitle: "EL PRINCIPIO DE UN LEGADO",
        cardSubtitle: "Pond's fue fundado por el farmacista Theron T. Pond cuando descubrió las propiedades curativas del hamamelis.",
        media: {
            name: "Ponds1846",
            source: { url: Ponds1846 },
            type: "IMAGE",
        },
    },
    {
        title: "1857",
        cardTitle: "POND'S EXTRACT: 1RA GENERACIÓN DEL SUERO REPARADOR",
        cardSubtitle: "El hamamelis de Pond's se envasa como Pond's Extract: el primer producto para el cuidado de la piel disponible comercialmente en el mundo.",
        media: {
            name: "Ponds1857",
            source: { url: Ponds1857 },
            type: "IMAGE",
        },
    },
    {
        title: "1907",
        cardTitle: "1RA CREMA FRÍA Y CREMA EVANESCENTE",
        cardSubtitle: "Pond's Cold Cream es la primera crema hidratante del mundo que no necesita refrigeración. Mientras que Vanishing Cream es la primera que se absorbe por completo, dejando la piel más suave y luminosa.",
        media: {
            name: "Ponds1907",
            source: { url: Ponds1907 },
            type: "IMAGE",
        },
    },
    {
        title: "1924",
        cardTitle: "1RA CAMPAÑA DE PRESTIGIO",
        cardSubtitle: "La realeza europea avala las cremas Pond's en una primicia publicitaria.",
        media: {
            name: "Ponds1924",
            source: { url: Ponds1924 },
            type: "IMAGE",
        },
    },
    {
        title: "1978",
        cardTitle: "1RA PATENTE DE NIACINAMIDA",
        cardSubtitle: "Por primera vez, la vitamina B3 o niacinamida se utiliza en el cuidado de la piel por sus propiedades iluminadoras.",
        media: {
            name: "Ponds1978",
            source: { url: Ponds1978 },
            type: "IMAGE",
        },
    },
    {
        title: "1992",
        cardTitle: "SE CREA EL POND'S SKIN INSTITUTE",
        cardSubtitle: "Pond's Institute se establece en más de 60 países.",
        media: {
            name: "Ponds1992",
            source: { url: Ponds1992 },
            type: "IMAGE",
        },
    },
    {
        title: "1994",
        cardTitle: "LA INTRODUCCIÓN DE AHAS EN PRODUCTOS DE ANTI-EDAD",
        cardSubtitle: "Pond's se convierte en la primera marca de gran consumo que incluye AHA en sus fórmulas anti-edad.",
        media: {
            name: "Ponds1994",
            source: { url: Ponds1994 },
            type: "IMAGE",
        },
    },
    {
        title: "2006",
        cardTitle: "PRIMERA GAMA ANTI-EDAD CON CLA & RETINOL",
        cardSubtitle: "El primero en mejorar la potencia del retinol con la adición de CLA, revolucionando por completo la tecnología anti-edad.",
        media: {
            name: "Ponds2006",
            source: { url: Ponds2006 },
            type: "IMAGE",
        },
    },
    {
        title: "2024",
        cardTitle: "AGE MIRACLE & BRIGHT MIRACLE",
        cardSubtitle: "Pond´s vuelve a revolucionar en el cuidado facial con dos nuevas líneas de productos con tecnología avanzada Anti-Edad y Anti-Manchas. Creando 2 rutinas de cuidado superior con resultados impactantes a partir de los 7 días del uso continuo.",
        media: {
            name: "Ponds2024",
            source: { url: Ponds2024 },
            type: "IMAGE",
        },
    },
];

const AcercaDePonds = () => {
    const isDesktop = useMediaQuery({ minWidth: 768 });

    useEffect(() => {
        // Remove elements with class names that start with "ToolbarWrapper-sc"
        const toolbars = document.querySelectorAll('[class^="ToolbarWrapper-sc"]');
        toolbars.forEach(toolbar => toolbar.remove());

    }, []);

    return (
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <Helmet>
                <title>Acerca de Ponds Skin Institute | Ponds Institute Mexico</title>
                <meta name="description" content="El mejor conocimiento científico al servicio del cuidado de la piel de los tiempos modernos. El POND's Skin Institute se formó para combinar décadas de ciencia en el cuidado de la piel con las primeras fórmulas de productos innovadores en el mercado a través de una red global de más de 700 investigadores."/>
            </Helmet>
            <DataLayer
                pageName="Ponds Acerca de Ponds Skin Institute"
                pageLanguage="es-MX"
                contentType="article"
                brandCategory="Beauty & Wellbeing"
                siteSection1="Ponds Institute Mexico"
                siteSection2="Acerca de Ponds"
                siteSection3=""
            />
            <div>
                <video controls autoPlay loop className="w-full">
                    <source src="/acerca-de-ponds.mp4" type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </div>
            <h1 className="font-title text-2xl my-4">El POND'S Skin Institute</h1>
            <p className="font-body text-lg my-4">
                El mejor conocimiento científico al servicio del cuidado de la piel de los tiempos modernos.
                El POND's Skin Institute se formó para combinar décadas de ciencia en el cuidado de la piel con las
                primeras fórmulas de productos innovadores en el mercado a través de una red global de más de 700
                investigadores.
            </p>
            <p className="font-body text-lg my-4">
                El POND's Skin Institute sigue siendo punta de lanza en el cuidado de la piel por su labor:
            </p>
            <ul className="custom-bullet-ul font-body text-lg my-4">
                <li>Analizando las necesidades cambiantes de los distintos tipos de piel alrededor del mundo.</li>
                <li>Investigando cómo estos factores afectan las percepciones de la gente.</li>
                <li>Diseñando moléculas probadas clínicamente para brindar resultados superiores en la piel.</li>
            </ul>
            <h2 className="font-title text-xl my-4">Conoce nuestra historia y legado:</h2>
            <Chrono
                items={items}
                mode={isDesktop ? "HORIZONTAL" : "VERTICAL_ALTERNATING"}
                hideControls={true}
                showAllCardsHorizontal={true}
                itemWidth={330}
                theme={{
                    primary: "#fcb8d2",
                    secondary: "#ff8db2",
                    cardTitleColor: "#000000",
                    cardBgColor: "#ffffff",
                    cardForeColor: "#000000",
                    titleColor: "#000000",
                    titleColorActive: "#ffffff"
                }}
            />
        </div>
    );
};

export default AcercaDePonds;
