import React from 'react';
import ImageGallery from 'react-image-gallery';
import './ImageGalleryComponent.css';

const ImageGalleryComponent = ({ images, category }) => {
    const getImage = (src) => {
        try {
            return require(`../images/${src}`);
        } catch (error) {
            console.error(error);
            return '';
        }
    }

    const imageItems = images.map(image => ({
        original: getImage(image),
        thumbnail: getImage(image),
        originalClass: `gallery-image ${category}`,
    }));

    return (
        <div className={`gallery-container `}>
            <ImageGallery items={imageItems} />
        </div>
    );
};

export default ImageGalleryComponent;