import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faMinus, faTimes} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { Helmet } from 'react-helmet-async';

Modal.setAppElement('#root');

const ProductInfo = ({product}) => {
    const [isOpen, setIsOpen] = useState({
        productData: true,
        ingredients: false,
        usage: false
    });
    const getImage = (src) => {
        try {
            return require(`../images/${src}`);
        } catch (error) {
            console.error(error);
            return '';
        }
    }

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const toggleAccordion = (section) => {
        setIsOpen({
            ...isOpen,
            [section]: !isOpen[section]
        });
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    function stripHtmlTags(str) {
        if ((str===null) || (str===''))
            return false;
        else
            str = str.toString();
        return str.replace(/<[^>]*>/g, '');
    }

    return (
        <div className="flex flex-col md:w-1/2 mx-3 ">
            <Helmet>
                {Object.entries(product.meta).map(([content], index) => {
                    if (content.name === 'title') {
                        return <title key={`meta${index}`}>{content.content}</title>
                    }
                    return <meta key={`meta${index}`} name={content.name} content={content.content} />
                })}
            </Helmet>
            <p className="text-lg my-2">{product.category}</p>
            <div className="flex items-baseline mb-6">
                <div className="font-bold text-2xl mr-2 product-name" dangerouslySetInnerHTML={{ __html: product.officialName }}></div>
                <div className="text-sm text-gray-500">{product.weight}</div>
            </div>
            <div className="mb-8" dangerouslySetInnerHTML={{__html: product.shortDescription}}/>
            <div className="border-b border-gray-500 overflow-hidden mb-0">
                <button className="w-full text-left p-2 font-title" onClick={() => toggleAccordion('productData')}>
                    DATOS DEL PRODUCTO
                    <FontAwesomeIcon icon={isOpen.productData ? faMinus : faPlus} className="float-right"/>
                </button>
                {isOpen.productData && (
                    <div className="p-2" dangerouslySetInnerHTML={{__html: product.longDescription}}/>
                )}
            </div>
            <div className="border-b border-gray-500 overflow-hidden mb-0">
                <button className="w-full text-left p-2 font-title" onClick={() => toggleAccordion('ingredients')}>
                    INGREDIENTES
                    <FontAwesomeIcon icon={isOpen.ingredients ? faMinus : faPlus} className="float-right"/>
                </button>
                {isOpen.ingredients && (
                    <div className="p-2">
                        <p>{product.ingredients}</p>
                    </div>
                )}
            </div>
            <div className="overflow-hidden mb-4">
                <button className="w-full text-left p-2 font-title" onClick={() => toggleAccordion('usage')}>
                    MODO DE USO
                    <FontAwesomeIcon icon={isOpen.usage ? faMinus : faPlus} className="float-right"/>
                </button>
                {isOpen.usage && (
                    <div className="p-2">
                        <p>{product.usage}</p>
                    </div>
                )}
            </div>
            <button className="bg-black text-white rounded p-2 mt-4" onClick={openModal}>COMPRAR</button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={true}
                contentLabel="Product Modal"
                overlayClassName="Overlay"
                className="Modal"
            >
                <button onClick={closeModal} className="absolute top-0 right-0 m-2">
                    <FontAwesomeIcon icon={faTimes}/>
                </button>
                <div className="flex p-10">
                    <div className="w-1/2 flex flex-col justify-center">
                        <img src={getImage(product.images[0])} alt={stripHtmlTags(product.officialName)}
                             className="mx-auto w-1/2"/>
                    </div>
                    <div className="w-1/2 flex flex-col justify-center">
                        <p className="text-md my-2">{product.category}</p>
                        <div className="flex items-baseline mb-6">
                            <div className="font-bold text-xl mr-2 product-name"
                                 dangerouslySetInnerHTML={{__html: product.officialName}}></div>
                            <div className="text-sm text-gray-500">{product.weight}</div>
                        </div>
                    </div>
                </div>
                <div className="bg-pinkish flex justify-center items-center p-10 flex-wrap">
                    <div className="font-title text-center w-full mb-4">
                        COMPRAR EN LÍNEA
                    </div>
                    {Object.entries(product.purchaseLinks)
                        .filter(([url]) => url !== "")
                        .map(([storeKey, url]) => (
                        <div className="flex flex-col items-center mb-4 px-4" key={storeKey}>
                            <img src={require(`../images/${storeKey}-logo.png`)} className="max-h-10" alt={storeKey}/>
                            <a href={url} target="_blank" rel="noreferrer noopener"
                               className="bg-black text-white rounded p-2 mt-2">COMPRA AQUI</a>
                        </div>
                    ))}
                </div>
            </Modal>
        </div>
    );
};

export default ProductInfo;