import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

const ImageReveal = ({ imageA, imageB }) => {
    const [hovered, setHovered] = useState(false);

    // Define the spring animation for the hover effect
    const slideIn = useSpring({
        to: {
            transform: hovered ? 'translateX(0%)' : 'translateX(100%)'
        },
        from: {
            transform: 'translateX(100%)'
        },
        // Ajustar la configuración para una transición más suave
        config: {
            tension: 110, // Menor tensión hace que la transición sea más suave
            friction: 26  // Un poco más de fricción para que la transición no sea tan rápida
        }
    });

    return (
        <div
            className="relative cursor-pointer overflow-hidden w-full h-full" // Asegúrate de que el ancho y el alto estén establecidos
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {/* Image B es la imagen que permanece en su lugar */}
            <img src={imageA} className="w-full h-full" alt="Primary" />
            {/* Animated Image A desliza sobre el hover */}
            <animated.img
                src={imageB}
                className="w-full h-full absolute top-0 left-0"
                style={slideIn}
                alt="Secondary"
            />
        </div>
    );
};

export default ImageReveal;
