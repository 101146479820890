import React from 'react';
import BubbleBackground from '../images/partiulas-titulos.png';
import './SectionTitle.css';

const SectionTitle = ({ title, id = null }) => {
    return (
        <div className="relative flex items-center justify-center" id={id}>
            <div className="absolute inset-x-0 top-1/2 border-t-2 border-black"></div>
            <div className="z-10 relative bg-white px-4 py-2 text-center">
                <span className="relative z-20 section-title">{title}</span>
                <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2">
                    <img
                        src={BubbleBackground}
                        alt="Bubble background"
                        className="h-auto"
                        style={{ height: '30px' }} // adjust height accordingly
                    />
                </div>
            </div>
        </div>
    );
};

export default SectionTitle;
