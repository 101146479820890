import React from 'react';
import DataLayer from "../components/DataLayer";
import {Helmet} from "react-helmet-async";


const AiSkinExpert = () => {

    return (
        <div className="w-full">
            <Helmet>
                <title>AI Skin Expert | Ponds Institute Mexico</title>
                <meta name="description"
                      content="El mejor conocimiento científico al servicio del cuidado de la piel de los tiempos modernos. El POND's Skin Institute se formó para combinar décadas de ciencia en el cuidado de la piel con las primeras fórmulas de productos innovadores en el mercado a través de una red global de más de 700 investigadores."/>
                <script type="module" src="https://aiba.unileversolutions.com/ponds/es-mx/skinanalyzer/v1/consumer.esm.js"></script>

                <link rel="stylesheet"
                      href="https://aiba.unileversolutions.com/ponds/es-mx/skinanalyzer/v1/consumer.css" />

                <link rel="stylesheet" href="https://aiba.unileversolutions.com/ponds/es-mx/skinanalyzer/v1/componentcss/aiba-component-library.css" />

            </Helmet>
            <DataLayer
                pageName="AI Skin Expert"
                pageLanguage="es-MX"
                contentType="article"
                brandCategory="Beauty & Wellbeing"
                siteSection1="Ponds Institute Mexico"
                siteSection2="Ai Skin Expert"
                siteSection3=""
            />
            <div>
                <aiba-app></aiba-app>
            </div>
        </div>
    );
};

export default AiSkinExpert;
