// App.js update
import './App.css';
import MainLayout from "./MainLayout";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'

import { HelmetProvider } from 'react-helmet-async';

library.add(fas, faTwitter, faFontAwesome);

function App() {
  return (
      <HelmetProvider>
        <MainLayout></MainLayout>
      </HelmetProvider>
  );
}

export default App;
