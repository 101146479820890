import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import fb from './images/F_FB.png';
import tw from './images/F_X.png';
import ig from './images/F_IG.png';
import yt from './images/F_Youtube.png';
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import ProductPage from './pages/ProductPage';
import AcercaDePonds from "./pages/AcercaDePonds";
import AiSkinExpert from "./pages/AiSkinExpert";

const MainLayout = () => {
    return (
        <Router>
            <div className="flex flex-col min-h-screen">
                {/* Header */}
                <Header></Header>
                {/* Main content */}
                <main className="flex-grow">
                    <Routes>
                        <Route path="/acerca-de-ponds-skin-institute" element={<AcercaDePonds/>}/>
                        <Route path="/producto/:category/:productName" element={<ProductPage/>}/>
                        <Route path="/ai-skin-expert" element={<AiSkinExpert/>}/>
                        <Route path="/" element={<HomePage/>}/>
                    </Routes>
                </main>

                {/* Footer */}
                <footer className="bg-black mt-6">
                    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4 flex flex-col items-center">
                        <div className="flex mb-4">
                            {/* Social icons */}
                            <a href="https://www.facebook.com/pondsmexico?mibextid=LQQJ4d" target="_blank"
                               rel="noopener noreferrer" className="text-white hover:text-gray-300 mx-2">
                                <img src={fb} alt="Facebook" className="max-h-8"/>
                            </a>
                            <a href="https://x.com/ponds_mexico?s=21&t=mOwGJs2OA3bGOS5sP6D2zw" target="_blank"
                               rel="noopener noreferrer" className="text-white hover:text-gray-300 mx-2">
                                <img src={tw} alt="X" className="max-h-8"/>
                            </a>
                            <a href="https://www.instagram.com/pondsmexico?igsh=bWV0NWhxd3d5a25u" target="_blank"
                               rel="noopener noreferrer" className="text-white hover:text-gray-300 mx-2">
                                <img src={ig} alt="Instagram" className="max-h-8"/>
                            </a>
                            <a href="https://youtube.com/@PondsMexico?si=ArEugNQQzg8mqjBC" target="_blank"
                               rel="noopener noreferrer" className="text-white hover:text-gray-300 mx-2">
                                <img src={yt} alt="Youtube" className="max-h-8"/>
                            </a>
                        </div>
                        <div className="flex">
                            <a href="https://www.ponds.com/mx/ayuda/contacto.html"
                               className="text-xs text-white hover:text-gray-300 mx-2" target="_blank"
                               rel="noopener noreferrer">CONTACTO</a>
                            <a href="https://www.unilever-northlatam.com/legal/"
                               className="text-xs text-white hover:text-gray-300 mx-2" target="_blank"
                               rel="noopener noreferrer">Aviso Legal</a>
                            <a href="https://www.unilevernotices.com/cookie-notices/mexico-spanish.html"
                               className="text-xs text-white hover:text-gray-300 mx-2" target="_blank"
                               rel="noopener noreferrer">Aviso de Cookies</a>
                            <a href="https://www.unilevernotices.com/privacy-notices/mexico-spanish.html"
                               className="text-xs text-white hover:text-gray-300 mx-2" target="_blank"
                               rel="noopener noreferrer">Aviso de Privacidad</a>
                            <a href="https://notices.unilever.com/es/mx/"
                               className="text-xs text-white hover:text-gray-300 mx-2" target="_blank"
                               rel="noopener noreferrer">Accesibilidad</a>
                        </div>
                        <div className="text-xs text-white mt-4">
                            © 2024 UNILEVER. ALL RIGHTS RESERVED.
                        </div>
                    </div>
                </footer>
            </div>
        </Router>
    );
};

export default MainLayout;
