import React from 'react';
import './CarouselComponents.css';

const defaultSpeed = 500;  // Default speed for animations
const rapidClickSpeed = 400;  // Reduced speed for rapid clicks

export const CarouselNextArrow = ({ className, style, onClick, setSliderSpeed }) => {
    const handleClick = () => {
        setSliderSpeed(rapidClickSpeed);
        onClick();
        setTimeout(() => setSliderSpeed(defaultSpeed), 200);  // Reset speed after the transition
    };

    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", color: "black" }}
            onClick={handleClick}
        />
    );
}

export const CarouselPrevArrow = ({ className, style, onClick, setSliderSpeed }) => {
    const handleClick = () => {
        setSliderSpeed(rapidClickSpeed);
        onClick();
        setTimeout(() => setSliderSpeed(defaultSpeed), 200);  // Reset speed after the transition
    };

    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", color: "black" }}
            onClick={handleClick}
        />
    );
}