import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import productData from '../productData.json';
import ImageGalleryComponent from '../components/ImageGalleryComponent';
import ProductInfo from '../components/ProductInfo';
import DataLayer from "../components/DataLayer";

const ProductPage = () => {
    const { category, productName } = useParams();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        // Find the product in the product data
        const foundProduct = productData.products.find(p => p.link === `/producto/${category}/${productName}`);
        setProduct(foundProduct);
    }, [category, productName]);

    if (!product) return <div>Producto no encontrado</div>;

    const bannerImage = require(category === 'age-miracle' ? '../images/BannerPrincipal-LineaAgeMiracle.jpg' : '../images/BannerPrincipal-LineaBrigthMiracle.jpg');

    return (
        <div>
            <DataLayer
                pageName={`Ponds ${product.category} ${product.officialName} ${product.weight}`}
                pageLanguage="es-MX"
                contentType="product detail"
                brandCategory="Beauty & Wellbeing"
                siteSection1="Ponds Institute Mexico"
                siteSection2={product.category}
                siteSection3={product.type}
            />
            <img src={bannerImage} alt={category} className="w-full" />
            <div className="flex flex-col md:flex-row mt-10 w-full max-w-screen-lg mx-auto">
                <ImageGalleryComponent images={product.images} category={category} />
                <ProductInfo product={product} />
            </div>
        </div>
    );
};

export default ProductPage;