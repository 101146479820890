import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const DataLayer = ({
                       pageName,
                       pageLanguage,
                       contentType,
                       brandCategory,
                       siteSection1,
                       siteSection2,
                       siteSection3,
                   }) => {
    const location = useLocation();

    useEffect(() => {
        const isMobile = () => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            return /android|blackberry|iemobile|ipad|iphone|ipod|opera mini|webos/i.test(userAgent);
        };

        const channelVal = isMobile() ? 'Mobile Site' : 'Brand Site';

        window.digitalData = {
            siteInfo: {
                channel: channelVal,
                sitetype: 'Non-Avinash/Non-D2/CMS Name',
            },
            page: {
                pageInfo: {
                    destinationURL: window.location.href,
                    pageName: pageName,
                    language: pageLanguage,
                },
                category: {
                    pageType: contentType,
                    primaryCategory: channelVal,
                    subCategory1: siteSection1,
                    subCategory2: siteSection2,
                    subCategory3: siteSection3,
                },
                attributes: {
                    contentType: contentType,
                    articleName: '',
                    brandCategory: brandCategory,
                    country: 'mexico',
                    globalBrand: 'ponds',
                    localBrand: 'ponds',
                },
            },
            video: [],
            campaign: [],
            product: [],
            privacy: {
                accessCategories: [{ domains: [] }],
            },
            component: [],
            trackingInfo: {
                GID: '',
                un: '',
                tool: [{ id: 'G-CNPZPHX6W0' }, { id: 'unileverlatam-pondsmexico-mx' }],
            },
            promotion: [],
            event: [],
        };

        window.digitalData.siteInfo.internalDomain = 'pondsmexico.mx';

        // After setting the window.digitalData
        const script = document.createElement('script');
        script.src = "//assets.adobedtm.com/e6bd1902389a/a147a5a958b3/launch-0ba81887b766.min.js";
        script.async = true;

        // Append the script to the body
        document.body.appendChild(script);

        // Cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, [location, pageName, pageLanguage, contentType, brandCategory, siteSection1, siteSection2, siteSection3]);

    return null;
};

export default DataLayer;
