import React from 'react';
import { useSpring, animated } from 'react-spring';
import TextureCreamJpg from "../images/Textura_CremaFacial.jpg";
import Textura_LimpiadorFacialJpg from "../images/Textura_LimpiadorFacial.jpg";
import Textura_CremaOjosJpg from "../images/Textura_CremaOjos.jpg";
import Textura_SerumsJpg from "../images/Textura_Serums.jpg";
import TextureCreamPng from "../images/Textura_CremaFacial.png";
import Textura_LimpiadorFacialPng from "../images/Textura_LimpiadorFacial.png";
import Textura_CremaOjosPng from "../images/Textura_CremaOjos.png";
import Textura_SerumsPng from "../images/Textura_Serums.png";

import './ProductCard.css';
import {Link} from "react-router-dom";

const getProductTexture = (productType) => {
    switch (productType) {
        case 'CremaFacial':
            return Math.random() < 0.5 ? TextureCreamJpg : TextureCreamPng;
        case 'LimpiadorFacial':
            return Math.random() < 0.5 ? Textura_LimpiadorFacialJpg : Textura_LimpiadorFacialPng;
        case 'CremaOjos':
            return Math.random() < 0.5 ? Textura_CremaOjosJpg : Textura_CremaOjosPng;
        case 'Serums':
            return Math.random() < 0.5 ? Textura_SerumsJpg : Textura_SerumsPng;
        default:
            return '';
    }
};

const ProductCard = ({ productType, productName, productImage, brand, weight, link }) => {
    // Define two springs here, one for the hover effect and one for the initial animation
    const cardAnimation = useSpring({
        from: { transform: 'translateY(20px)', opacity: 0 },
        to: { transform: 'translateY(0)', opacity: 1 },
        config: { duration: 500 },
    });

    const [hoverProps, setHoverProps] = useSpring(() => ({
        transform: 'scale(1)',
    }));

    // Update hoverProps when hovering
    const handleHover = (hovering) => {
        setHoverProps({
            transform: hovering ? 'scale(1.08)' : 'scale(1)',
            config: { mass: 1, tension: 300, friction: 10 },
        });
    };

    return (
        <animated.div
            className="flex flex-col p-4 bg-white product-card"
            style={cardAnimation}
            onMouseEnter={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
        >
            <Link to={link}>
                <div
                    className="w-56 h-56 bg-cover bg-center rounded-full mb-4"
                    style={{backgroundImage: `url(${getProductTexture(productType)})`}}
                >
                    <animated.img
                        src={productImage}
                        alt={productName}
                        className="w-full h-full object-contain"
                        style={hoverProps}
                    />
                </div>
            </Link>
            <div className="product-content"> {/* Nueva clase para el contenido */}
                <div className="text-sm">{brand}</div>
                <div className="flex items-baseline mb-2">
                    <div className="font-bold text-lg mr-1 product-name min-h-16" dangerouslySetInnerHTML={{ __html: productName }}></div>
                    <div className="text-sm text-gray-500">{weight}</div>
                </div>
                <div className="w-full text-center">
                    <Link
                        to={link}
                        className="text-white w-24 bg-black rounded-full px-4 py-1 text-xs font-semibold tracking-wide hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-opacity-75"
                    >
                        VER MÁS
                    </Link>
                </div>
            </div>
        </animated.div>
    );
};

export default ProductCard;