import React, { useState, useEffect, useContext } from 'react';
import { useSpring, animated } from 'react-spring';

// Import images
import p1 from '../images/p1.png';
import p2 from '../images/p2.png';
import p3 from '../images/p3.png';
import p4 from '../images/p4.png';
import p5 from '../images/p5.png';
import p6 from '../images/p6.png';
import p7 from '../images/p7.png';

// Bubble assets and sizes
const bubbleImages = [p1, p2, p3, p4, p5, p6, p7];
const bubbleSizes = [
    { width: '20rem', height: '15rem' },
    { width: '17rem', height: '16.5rem' },
    { width: '10rem', height: '10.5rem' },
    { width: '9rem', height: '9.3rem' },
    { width: '5.5rem', height: '5.3rem' },
    { width: '2.4rem', height: '2.3rem' },
    { width: '2.4rem', height: '2.3rem' },
];

// Context to provide container dimensions
const ContainerDimensionsContext = React.createContext({ width: 0, height: 0 });

const AnimatedImage = ({ src, size, index }) => {
    const { width: containerWidth, height: containerHeight } = useContext(ContainerDimensionsContext);
    const speed = 5000 + Math.random() * 10000; // Random speed between 5 and 15 seconds

    // Convert REM to pixels assuming the root font-size is 16px (default)
    const imageSizeWidth = parseFloat(size.width) * 16;
    const imageSizeHeight = parseFloat(size.height) * 16;

    // Adjusting the maximum boundary for movement
    const maxWidth = (containerWidth * 0.6) - imageSizeWidth;
    const maxHeight = (containerHeight * 0.6) - imageSizeHeight;

    // Generate initial positions with configurable constraints
    const { initialX, initialY } = generateInitialPosition(containerWidth, containerHeight, imageSizeWidth, imageSizeHeight);

    const anim = useSpring({
        from: {
            transform: `translate(${initialX}px, ${initialY}px) scale(1) rotate(0deg)`,
            opacity: 0.8,
        },
        to: async (next) => {
            while (1) {
                // Generate new position within bounds
                const newX = Math.random() * maxWidth;
                const newY = Math.random() * maxHeight;

                await next({
                    transform: `translate(${newX}px, ${newY}px) scale(${0.8 + Math.random() * 0.4}) rotate(${Math.random() * 360}deg)`,
                    opacity: 0.5 + Math.random() * 0.5,
                });
            }
        },
        config: { duration: speed },
        reset: true,
    });

    return (
        <animated.img
            src={src}
            className="absolute"
            style={{
                ...anim,
                width: size.width,
                height: size.height,
            }}
            alt={`Bubble ${index + 1}`}
        />
    );
};

// Function to generate initial position based on container and image sizes
function generateInitialPosition(containerWidth, containerHeight, imageSizeWidth, imageSizeHeight) {
    const xPadding = (containerWidth * 0.2); // Example: 20% of container width as padding
    const yPadding = (containerHeight * 0.1); // Example: 10% of container height as padding

    const initialX = Math.random() * (containerWidth*-0.2 - imageSizeWidth - xPadding) + xPadding;
    const initialY = Math.random() * (containerHeight*0.5 - imageSizeHeight - yPadding) + yPadding;

    return { initialX, initialY };
}

const BubblesBackground = ({ containerRef }) => {
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        if (containerRef.current) {
            setContainerSize({
                width: containerRef.current.offsetWidth,
                height: containerRef.current.offsetHeight
            });
        }
    }, [containerRef]);

    return (
        <ContainerDimensionsContext.Provider value={containerSize}>
            <div className="relative flex flex-col items-center justify-center w-full min-h-80">
                <div className="z-10 w-full text-center">
                    <h1 className="text-4xl font-bubbles">SKIN INSTITUTE</h1>
                </div>
                {bubbleImages.map((src, index) => {
                    const count = index < 4 ? 2 : 5;
                    return Array.from({ length: count }, (_, i) => (
                        <AnimatedImage key={`${index}-${i}`} src={src} size={bubbleSizes[index]} index={index} />
                    ));
                })}
            </div>
        </ContainerDimensionsContext.Provider>
    );
};

export default BubblesBackground;
